<template>
  <div>
    <el-table
      ref="table"
      :header-cell-style="headerstyle"
      :cell-style="bigstyle"
      :data="tableData"
      :row-key="getRowKeys"
      :expand-row-keys="expands"
      style="width: 100%"
    >
      <el-table-column label="序号" width="72" prop="order"  align="center" > </el-table-column>
      <el-table-column label="作业名称" width="127" prop="name">
      </el-table-column>
      <el-table-column label="班级名称" prop="className" :formatter="semesterName">
      </el-table-column>
      <el-table-column label="截止时间" :formatter = 'formatendTime' width="170" prop="endTime"> </el-table-column>
      <el-table-column label="状态" :formatter = 'formatterPubArea1' width="65"  prop="type"> </el-table-column>
      <el-table-column label="作业要求" prop="remarks" width="150">
        <template slot-scope="scope">
          <p class="remarks" style="width:150px">{{ scope.row.remarks }}</p>
        </template>
      </el-table-column>
      <el-table-column label="操作" prop="desc">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="fn(scope.row)">
            详情<img style="width:12px;height:12px;margin-left:3px;vertical-align:middle;" v-if="expands.indexOf(scope.row.id)<0" src="../../../assets/down.png" alt="">
            <img style="width:12px;height:12px;margin-left:3px; vertical-align:middle;" v-else src="../../../assets/up.png" alt="">
          </el-button>
          <el-button type="text" size="small" v-if="scope.row.type ===0" @click="jumper(scope.row)">
            提交更多
          </el-button>
        </template>
      </el-table-column>
      <el-table-column type="expand" width="1" >
        <template slot-scope="scope">
          <el-table
            class="table2"
            :cell-style="cellStyle"
            :data="scope.row.table"
            :header-cell-style="cellStyle2"
            style="background-color:#fbfbfb"
          >
            <el-table-column width="72"> </el-table-column>
            <el-table-column type="index" label="作品名称" width="127">
                <template slot-scope="scope">
                    {{scope.row.name}}
                </template>
            </el-table-column>
            <el-table-column width="220" type="index"  label="提交时间">
                <template slot-scope="scope">
                    {{formatendTime1(scope.row.submitTime)}}
                </template>
            </el-table-column>
            <el-table-column prop="bei" width="420" type="index" label="备注说明">
              <template slot-scope="scope">
                <P class="remarks" style="width: 420px;">
                  {{ scope.row.remarks }}
                </P>
              </template>
            </el-table-column>
            <el-table-column prop="date" type="index">
              <template slot-scope="scope">
                <el-button  style="font-size:13px;" type="text" size="small" @click="jumperdetails(scope.row)">
                  查看
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {train_taskSongList} from '../../../api/train'
export default {
    props: {
       'obj':Object
    },
  data () {
    return {
      tableData: [],
      //   表格头部样式
      headerstyle: {
        'backgroundColor': '#f7f7f7',
        'fontSize': '14px',
        'fontFamily': 'PingFang SC, PingFang SC-Regular',
        'fontWeight': 400,
        'color': '#333333',
        'border': 'none',
      },
      bigstyle:{
       border:'none',
       'borderBottom': '1px solid #f1f1f1'
      },
      cellStyle: {
        'fontSize':'13px',
        'border': 'none',
        'padding': '0px',
        'height': '10px',
        'backgroundColor': '#fbfbfb',
      },
      cellStyle2: {
        'fontSize': '13px',
        'fontFamily': 'PingFang SC, PingFang SC-Semibold',
        'fontWeight': 600,
        'textAlign': 'LEFT',
        'color': '#aaaaaa',
        'backgroundColor': '#fbfbfb',
        'border':'none',

      },
      // 获取row的key值
      getRowKeys (row) {
        return row.id;
      },
      // 要展开的行，数值的元素是row的key值
      expands: [],

    }
  },
  methods: {
       formatterPubArea1(value){
       let typename = ""
       switch(value.type){
            case -1:
            typename="已取消"
            break;
             case 0:
            typename="上交中"
            break;
            case 1:
            typename="已结束"
            break;
       }
       return typename
  },
    fn (row) {
      if (this.expands.indexOf(row.id) === -1) {
        this.expands.push(row.id);
        if(row.table !== undefined){
          return
        }
        train_taskSongList(row.id).then((res)=>{
          //标记作业类型
          res.data.forEach(item => {
            item.classification = row.classification
          })
           this.$set(row, 'table', res.data);
        })
      } else {
        this.expands.splice(this.expands.indexOf(row.id, 1))
      }

    },
    jumperdetails(val) {
          if(val.classification === 0){
            this.$router.push({
                path:'/work-details', query:{id:val.id}
            })
          } else {
            this.$router.push('/video-details/'+val.id)
          }

    },
    jumper(val){
        this.$router.push({path:'/submitjob', query: { id: val.id,classId:val.classId}})
    },
    formatendTime(value){
     return this.$timestampToString(value.endTime)
    },
     formatendTime1(value){
     return this.$timestampToString(value)
    },
    semesterName(val){
       if(val !=undefined){
           return `${val.className}(${val.semesterName})`
       }
    }
  },
  watch:{
    obj:{
        deep:true,
        handler(newV){
            this.tableData=newV.list;
             this.tableData.forEach((item,index)=>{
              item.order=(newV.pageNum-1)*10+(1+index);
          })
        }
    },

  }
}
</script>

<style scoped lang="scss">
::v-deep .el-table__expanded-cell {
  background-color: #fdfdfd;
  padding: 0px;
  .el-table {
    padding-left: 0px;
  }
}
.remarks {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-button{
font-size: 14px;
}
.el-table::before {
    height: 0px;
 }
</style>
