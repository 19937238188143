// 作品管理
<template>
  <div class="works">
    <left-menu current="taskrun" />
    <div class="main">
      <div class="header">
        <div class="radio-header">
          <span @click="radioIndex=0" class="radio" :class="radioIndex===0?'radio-active':''">未上交</span>
          <span  @click="radioIndex=1" class="radio" :class="radioIndex===1?'radio-active':''" >已上交</span>
        </div>
      </div>
      <Nothandedin :obj.sync="obj" v-if="radioIndex===0" />
      <Submitted :obj.sync="obj"  v-else />
      <div class="curindex"> <el-pagination
        background
        small
      :page-size="pageSize"
      @current-change="currentChange"
      layout="prev, pager, next, total, jumper"
      :total="total">
    </el-pagination></div>
    </div>
    
  </div>
</template>

<script>
import LeftMenu from '../../components/LeftMenu.vue'
import Nothandedin from './components/Nothandedin.vue'
import Submitted from './components/Submitted.vue'
import {train_taskList} from '../../api/train';
export default {
  components: {
    LeftMenu,
    Nothandedin,
    Submitted,
    
  },
  data () {
    return {
      radioIndex: 0,
      pageNum:1,
      pageSize:10,
      obj:{},
      total:0
    }
  },
created (){
 // 判断页面是否展示已上交表格
 if(this.$route.params.submitState){
  this.radioIndex=this.$route.params.submitState 
 }
  this.taskList()
},
methods: {
    taskList(){
        let params={
               type:this.radioIndex,
               pageNum:this.pageNum,
               pageSize:this.pageSize
        }
      train_taskList(params).then(res=>{
           if(res.code == 0){
               this.obj=res.data;
              this.total=res.data.total;
           }
      })
    },
    currentChange(val){
       this.pageNum=val
       this.taskList()
    }
},
watch: {
    'radioIndex'(){
        this.pageNum=1
        this.taskList()
    }
}
}
</script>

<style scoped lang="scss">
@import "../../styles/initialization.scss";
@import "../../styles/works.scss";
.header {
    margin-bottom: 40px;
  .radio {
    display: inline-block;
    width: 100px;
    height: 40px;
    line-height: 36px;
    text-align: center;
    background-color: #f4f4f4;
    border: 1px solid  #f4f4f4;
    font-size: 15px;
    font-family: PingFang SC, PingFang SC-Semibold;
    color:#999999;
    box-sizing: border-box;
    vertical-align: middle;
    cursor: pointer;
    font-weight: 400;
  }
  .radio-active {
        background-color: #ffffff;
        border:2px solid #f4f4f4;
        color:#333333;
        font-weight: 600;  
        cursor:default;
  }
}
.curindex{
      display: flex;
      justify-content: center;
      margin-top:26px;
  }
</style>