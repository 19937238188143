<template>
  <div>
  <el-table
    :data="tableData"
    :header-cell-style="headerstyle"
    style="width: 100%"
  >
    <el-table-column prop="order" label="序号" width="76px" align="center"> </el-table-column>
    <el-table-column prop="name" label="作业名称"> </el-table-column>
    <el-table-column prop="className" label="班级名称" :formatter="semesterName"> </el-table-column>
    <el-table-column prop="endTime"   width="200" :formatter = 'formatterPubArea' label="截止时间"> </el-table-column>
    <el-table-column prop="type" :formatter = 'formatterPubArea1' label="状态"> </el-table-column>
    <el-table-column label="操作" width="120">
      <template slot-scope="scope">
       <el-button type="text" size="small" @click="submitfn(scope.row)" :class="scope.row.type!==0?'button_active':''" > 交作业 </el-button>
      </template>
    </el-table-column>
  </el-table>
     
  </div>
</template>

<script>
export default {
  props:['obj'],
  data () {
    return {
      tableData: [],
    //   表格头部样式
      headerstyle: {
        'backgroundColor': '#f7f7f7',
        'fontSize': '14px',
        'fontFamily': 'PingFang SC, PingFang SC-Regular',
        'fontWeight': 400,
        'color': '#333333'
      }
    }
  },
computed:{
},
methods:{
    formatterPubArea(value){
        return this.$timestampToString(value.endTime);
    },
    formatterPubArea1(value){
       let typename = ""
       switch(value.type){
            case -1:
            typename="已取消"
            break;
             case 0:
            typename="上交中"
            break;
            case 1:
            typename="已结束"
            break;
       }
       return typename
  },
  submitfn(val){
     if(val.type==0){
         this.$router.push({path:'/submitjob',query:{id:val.id,classId:val.classId}})
         this.$store.state.jobdetails={
             name:val.name,
             className: val.className,
             createTime: val.createTime,
             endTime: val.endTime,
             remarks: val.remarks,
         }

         
     }
  },
  semesterName(val) {
    if(val!=undefined){
        return `${val.className}(${val.semesterName})`
    }
  }
},
 watch:{
     obj:{
        deep:true,
        handler:function(newV){ 
          this.tableData=newV.list;
          this.tableData.forEach((item,index)=>{
              item.order=(newV.pageNum-1)*10+(1+index);
          })
        }
     }
 }

}
</script>

<style scoped lang="scss">
  .button_active{
      color: #cccccc;
      cursor: default;
  }
  .el-button{
font-size: 14px;
}
</style>